import type { TNAPIBody, TNAPIQuery } from '~/utils/napi-helpers'

function listCompanyVerficationsReactive(
  query: TNAPIQuery<'/napis/v1/company-verifications/', 'get'>,
  asyncDataOptions: Record<string, any> = {}
) {
  const { openApiReactiveList } = useAuthFetch()
  return openApiReactiveList('/napis/v1/company-verifications/', undefined, query, asyncDataOptions)
}

function listCompanyVerificationsOnce(
  query: TNAPIQuery<'/napis/v1/company-verifications/', 'get'>
) {
  const { openAPIFetchOnce } = useAuthFetch()
  return openAPIFetchOnce('/napis/v1/company-verifications/', 'get', undefined, query)
}

function getCompanyVerification(verificationSlug: string) {
  const { openAPIFetchOnce } = useAuthFetch()
  const path = '/napis/v1/company-verifications/{company_verification_slug}/'
  const method = 'get'
  const pathParams = { company_verification_slug: verificationSlug }
  return openAPIFetchOnce(path, method, pathParams)
}

function deleteCompanyVerification(verificationSlug: string) {
  const { openAPIFetchOnce } = useAuthFetch()
  const path = '/napis/v1/company-verifications/{company_verification_slug}/'
  const method = 'delete'
  const pathParams = { company_verification_slug: verificationSlug }
  return openAPIFetchOnce(path, method, pathParams)
}

function patchCompanyVerification(
  verificationSlug: string,
  data: TNAPIBody<'/napis/v1/company-verifications/{company_verification_slug}/', 'patch'>
) {
  const { openAPIFetchOnce } = useAuthFetch()
  const path = '/napis/v1/company-verifications/{company_verification_slug}/'
  const method = 'patch'
  const pathParams = { company_verification_slug: verificationSlug }
  return openAPIFetchOnce(path, method, pathParams, undefined, data)
}

function createCompanyVerification(data: TNAPIBody<'/napis/v1/company-verifications/', 'post'>) {
  const { openAPIFetchOnce } = useAuthFetch()
  const path = '/napis/v1/company-verifications/'
  const method = 'post'
  return openAPIFetchOnce(path, method, undefined, undefined, data)
}

function createCompanyVerificationFileUploadUrl(
  data: TNAPIBody<'/napis/v1/company-verification-files/', 'post'>
) {
  const { openAPIFetchOnce } = useAuthFetch()
  const path = '/napis/v1/company-verification-files/'
  const method = 'post'
  return openAPIFetchOnce(path, method, undefined, undefined, data)
}

type UseCompanyVerificationReturnType = {
  listCompanyVerficationsReactive: typeof listCompanyVerficationsReactive
  getCompanyVerification: typeof getCompanyVerification
  createCompanyVerification: typeof createCompanyVerification
  patchCompanyVerification: typeof patchCompanyVerification
  createCompanyVerificationFileUploadUrl: typeof createCompanyVerificationFileUploadUrl
  deleteCompanyVerification: typeof deleteCompanyVerification
  listCompanyVerificationsOnce: typeof listCompanyVerificationsOnce
}

export function useCompanyVerification(): UseCompanyVerificationReturnType {
  return {
    listCompanyVerficationsReactive,
    getCompanyVerification,
    createCompanyVerification,
    patchCompanyVerification,
    createCompanyVerificationFileUploadUrl,
    deleteCompanyVerification,
    listCompanyVerificationsOnce
  }
}
